<template lang="pug">
  .wrapper
    MenuHeader
    main
      .inside-content
        .container
          //.pricelist-info * Сервис становится платным c 01 июля 2021 года
          h1.pricelist-title Тарифы
          .pricelist
            .pricelist__row.pricelist__row_header
              .pricelist__col.pricelist__col_long.pricelist__col_big
              .pricelist__col.pricelist__col_big
                button.pricelist__tab-button.pricelist__tab-button_orange.pricelist__tab-button_active(type="button" data-toggle-col="col-1")
                  span.pricelist__tab-button-title Пробный
                  span.pricelist__tab-button-desc Для тех, кто хочет попробовать
              .pricelist__col.pricelist__col_big
                button.pricelist__tab-button.pricelist__tab-button_green(type="button" data-toggle-col="col-2")
                  span.pricelist__tab-button-title Мой дом
                  span.pricelist__tab-button-desc Для тех, кто строит свой дом и хочет быть уверен в результате
              .pricelist__col.pricelist__col_big
                button.pricelist__tab-button.pricelist__tab-button_red(type="button" data-toggle-col="col-3")
                  span.pricelist__tab-button-title Профи
                  span.pricelist__tab-button-desc Для тех, кто дорожит своей репутацией профессионала
              .pricelist__col.pricelist__col_big
                button.pricelist__tab-button.pricelist__tab-button_purpul(type="button" data-toggle-col="col-4")
                  span.pricelist__tab-button-title Профи +
                  span.pricelist__tab-button-desc Для профессионалов, кому нужен безлимит
            .pricelist__row.pricelist__row_desc
              .pricelist__col.pricelist__col_active.col-1
                span.pricelist__col-value.pricelist__col-value_secondary Для тех, кто хочет попробовать
              .pricelist__col.col-2
                span.pricelist__col-value.pricelist__col-value_secondary Для тех, кто строит свой дом и хочет быть уверен в результате
              .pricelist__col.col-3
                span.pricelist__col-value.pricelist__col-value_secondary Для тех, кто дорожит своей репутацией профессионала
              .pricelist__col.col-4
                span.pricelist__col-value.pricelist__col-value_secondary Для профессионалов, кому нужен безлимит

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Количество расчетов

              .pricelist__col.pricelist__col_active.col-1
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 1

              .pricelist__col.col-2
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 1

              .pricelist__col.col-3
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 10

              .pricelist__col.col-4
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 Безлимит

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Срок действия
              .pricelist__col.pricelist__col_active.col-1
              .pricelist__col.col-2
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 3 мес
              .pricelist__col.col-3
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 1 год
              .pricelist__col.col-4
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 1 год

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Расчет теплопотерь упрощенный
                span.pricelist__row-desc Быстро и качественно по объему помещения, включая потери на вентиляцию
              .pricelist__col.pricelist__col_active.col-1
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 1 помещение
              .pricelist__col.col-2
                span.pricelist__col_check
              .pricelist__col.col-3
                span.pricelist__col_check
              .pricelist__col.col-4
                span.pricelist__col_check

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Расчет теплопотерь подробный
                span.pricelist__row-desc Точно как у проектировщиков, включая потери на вентиляцию
              .pricelist__col.pricelist__col_active.col-1
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 1 помещение
              .pricelist__col.col-2
                span.pricelist__col_check
              .pricelist__col.col-3
                span.pricelist__col_check
              .pricelist__col.col-4
                span.pricelist__col_check

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Расчет водяного теплого пола
                span.pricelist__row-desc Вы сможете моделировать разные режимы работы пола и выбрать оптимальный
              .pricelist__col.pricelist__col_active.col-1
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 1 помещение

              .pricelist__col.col-2
                span.pricelist__col_check
              .pricelist__col.col-3
                span.pricelist__col_check
              .pricelist__col.col-4
                span.pricelist__col_check

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Подбор радиаторов
                span.pricelist__row-desc По действительной теплоотдаче с учетом ваших температурных режимов
              .pricelist__col.pricelist__col_active.col-1
                span.pricelist__col-value.text-weight-semi-bold.text-indigo-5 1 помещение
              .pricelist__col.col-2
                span.pricelist__col_check
              .pricelist__col.col-3
                span.pricelist__col_check
              .pricelist__col.col-4
                span.pricelist__col_check

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Бессрочное хранение расчетов
              .pricelist__col.pricelist__col_active.col-1
                span.pricelist__col_check
              .pricelist__col.col-2
                span.pricelist__col_check
              .pricelist__col.col-3
                span.pricelist__col_check
              .pricelist__col.col-4
                span.pricelist__col_check

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Консультации по расчетам в онлайн-чате
              .pricelist__col.pricelist__col_active.col-1
              .pricelist__col.col-2
                span.pricelist__col_check
              .pricelist__col.col-3
                span.pricelist__col_check
              .pricelist__col.col-4
                span.pricelist__col_check

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Вставка вашего логотипа в штамп с отчетами
              .pricelist__col.pricelist__col_active.col-1
              .pricelist__col.col-2
                span.pricelist__col_check
              .pricelist__col.col-3
                span.pricelist__col_check
              .pricelist__col.col-4
                span.pricelist__col_check

            .pricelist__row
              .pricelist__col.pricelist__col_long
                span.pricelist__row-title Скачивание PDF отчетов
              .pricelist__col.pricelist__col_active.col-1
              .pricelist__col.col-2
                span.pricelist__col_check
              .pricelist__col.col-3
                span.pricelist__col_check
              .pricelist__col.col-4
                span.pricelist__col_check

            .pricelist__row
              .pricelist__col.pricelist__col_footer.pricelist__col_long.pricelist__col_footer
                span.pricelist__price-title(style="margin-top: -50px;") Цена
                //span.pricelist__price-desc Скидка 50% на покупку любого тарифа с 1 июля по 15 августа

              .pricelist__col.pricelist__col_footer.pricelist__col_active.col-1
                span.pricelist__price 0
                span.pricelist__price-subtitle Бесплатно
                button.pricelist__price-button.pricelist__price-button_border(type="button" @click="goSignInStart") Начать

              .pricelist__col.pricelist__col_footer.col-2
                span.pricelist__old-price 1900 ₽
                span.pricelist__price 275 ₽
                span.pricelist__price-subtitle(style="color: #ef5350;") Акция до 15.01.22
                button.pricelist__price-button.pricelist__price-button_green(type="button" @click="goSignInMoyDom") Купить

              .pricelist__col.pricelist__col_footer.col-3
                span.pricelist__old-price 7900 ₽
                span.pricelist__price 4450 ₽
                span.pricelist__price-subtitle 10 расчетов на 1 год
                button.pricelist__price-button.pricelist__price-button_green(type="button" @click="goSignInProfi") Купить

              .pricelist__col.pricelist__col_footer.col-4
                span.pricelist__old-price 15900 ₽
                span.pricelist__price 8450 ₽
                span.pricelist__price-subtitle Безлимит на 1 год
                button.pricelist__price-button.pricelist__price-button_green(type="button" @click="goSignInProfiPlus") Купить

          .pricelist-bottom-info
            span После окончания срока действия тарифа, вы сможете посмотреть все ваши расчеты.
            a(href="/tariffs.pdf" target="_blank") Смотреть все правила использования тарифов
    Footer
</template>

<script>
import MenuHeader from '../components/Header'
import Footer from '../components/Footer'
import navigationMixin from '../mixins/navigation'

export default {
  name: 'PaymentPlans',
  mixins: [navigationMixin],
  components: {
    MenuHeader,
    Footer
  },
  metaInfo: {
    meta: [
      {
        name: 'description',
        content: 'Тарифные планы для доступа к расчетам отопления.'
      }
    ],
    title: 'Тарифные планы Спроектируй.рф - сервис для расчета систем отопления'
  },
  mounted () {
    const pricelistButtons = document.querySelectorAll('.pricelist__tab-button')
    pricelistButtons.forEach((pricelistButton) => {
      pricelistButton.onclick = function (e) {
        pricelistButtons.forEach((element) => {
          element.classList.remove('pricelist__tab-button_active')
        })
        pricelistButton.classList.toggle('pricelist__tab-button_active')
        const toggleColClass = pricelistButton.getAttribute('data-toggle-col')
        const allCols = document.querySelectorAll('.pricelist__col')
        allCols.forEach((col) => {
          col.classList.remove('pricelist__col_active')
        })
        const toggleCol = document.querySelectorAll('.' + toggleColClass)
        toggleCol.forEach((col) => {
          col.classList.add('pricelist__col_active')
        })
      }
    })
  },
  methods: {
    goSignInStart () {
      this.$reachGoal('LANDING_CLICK_START')
      this.goSignUp()
    },
    goSignInMoyDom () {
      this.$reachGoal('LANDING_CLICK_MOY_DOM')
      this.goSignUp()
    },
    goSignInProfi () {
      this.$reachGoal('LANDING_CLICK_PROFI')
      this.goSignUp()
    },
    goSignInProfiPlus () {
      this.$reachGoal('LANDING_CLICK_PROFI_PLUS')
      this.goSignUp()
    }
  }
}
</script>
